<template>
    <v-text-field class="center-aligned"
    flat solo dense hide-details readonly background-color="blue-grey darken-3"
    :prefix="label + ':'" 
    :value="formatDate(value)"
    ></v-text-field>
</template>
<script>
export default {
    name: 'DateView',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
    }),
    computed: {
    },
    mounted() {
    },
    methods: {
        formatDate (date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            return d.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
        },
    },
}
</script>
<style scoped>
.center-aligned >>> input {
    text-align: center
}
</style>