<template>
	  <v-text-field
        flat solo dense hide-details readonly background-color="blue-grey darken-3"
        :prefix="!reverse[format] ? label + ':' : ''" 
        :suffix="reverse[format] ? label + ':' : ''" 
        :value="value"
        :reverse="reverse[format]"
    ></v-text-field>
</template>
<script>
export default {
    name: 'TextView',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'decimal': true, 'euro': true, 'proz': true }
    }),
    computed: {
	},
    mounted() {
    },
}
</script>
<style scoped>
</style>