<template>
    <v-card tile class="elevation-0 blue-grey darken-1" background-color="blue-grey darken-3">
    <v-container fluid>
        <v-row class="ma-0 pa-0">
            <v-col v-for="(field, index) in cfgForms" :key="index" :cols="field.width" class="ma-0 pa-1">
                <component :is="viewComp[field.type]"
                            :label="field.title"
                            :format="field.format"
                            :name="field.column_name"
                            :value="item[field.column_name]"
                            :options="field.opt"
                            :reftable="field.lookuptable"
                            :refcol="item[field.lookupcol]"
							class="view-comp"
                >
                </component>
            </v-col>
        </v-row>
    </v-container>
    </v-card>
</template>
<script>
import TextView from './components/TextView'
import DateView from './components/DateView'
import KWDateView from './components/KWDateView'
import NumberView from './components/NumberView'
import LookupView from './components/LookupView'
export default {
	name: 'GenericItem',
	components: {
        TextView, DateView, KWDateView, NumberView, LookupView,
	},
	props: {
		modulData: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data: () => ({
		loading: false,
        item: {},
        cfgTable: {},
        cfgForms: [],
		viewComp: {
			'default': 'TextView', 'number': 'NumberView', 'datepick': 'DateView', 'kw': 'KWDateView', 'select': 'TextView', 'lookup': 'LookupView',
		},
    }),
	computed: {
	},
	methods: {
		/*getData() {
			this.loading = true
			const param = {
				tablename: this.modulData.meta.tablename,
				data: this.modulData.params,
			}
			let self = this
			this.$store.dispatch('generic/getItem', param)
			.then((response) => {
				console.log(response)
				self.item = Object.assign(self.$store.getters['generic/getItem'])
				self.loading = false
			})
			.catch((error) => {
				console.log(error)
				self.loading = false
			});
		},*/
		getCfgs() {
			const param = {
				table_name: this.modulData.meta.tablename,
				reftable: this.modulData.meta.tablename,
			}
			if(this.reftable == 'none') { param.reftable = param.tablename }
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgForms = this.$store.getters['core/getCfgForms'](param)
			//console.log('genericItem [cfgForms]:', this.cfgForms)
		},
		getItemData() {
			this.item = Object.assign({}, this.modulData.meta.item)
			//console.log('genericItem [item]:', this.item)
		},
	},
	mounted() {
		//console.log('genericItem [modulData]:', this.modulData)
		this.getCfgs()
		this.getItemData()
		/*this.item = Object.assign({}, this.modulData.meta.item)
		console.log('genericItem [item]:', this.item)*/
		/*this.$nextTick(() => {
		});*/
		//this.getData()
	},
	watch: {
        modulData: {
            handler() {
				this.getItemData()
				//if(this.modulData.meta.item) this.getItemData()
            },
            deep: true,
        },
	},
};
</script>
<style scoped>
.view-comp >>> .truncate-text {
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-comp >>> .v-text-field__prefix {
    color: rgba(255, 255, 255, 0.3);
}
.view-comp >>> .v-text-field__suffix {
    color: rgba(255, 255, 255, 0.3);
}

.view-comp >>> .centered-input >>> input {
    text-align: center
}
</style>