<template>
    <v-text-field class="center-aligned"
    flat solo dense hide-details readonly background-color="blue-grey darken-3"
    :prefix="label + ':'" 
    :suffix="getRangeOfWeek(value)" 
    :value="formatDate(value)"
    ></v-text-field>
</template>
<script>
export default {
    name: 'KWDateView',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
    }),
    computed: {
    },
    mounted() {
    },
    methods: {
        formatDate (date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            const weekNumber = d.getWeekNumber()
            return weekNumber.week + '/' + weekNumber.year
        },
        getRangeOfWeek(date) {
            if (!date || date.search("-") < 0) return null
            const [year, month, day] = date.split('-')
            const d = new Date(year, month - 1, day);
            const weekNumber = d.getWeekNumber()
            const dw = new Date().getDateOfISOWeek(weekNumber.week, weekNumber.year)
            const dl = new Date(dw.getTime() - (dw.getTimezoneOffset() * 60000))
            const d7 = new Date(dl.valueOf())
            d7.setDate(d7.getDate() + 6)
            const dv = dl.toLocaleDateString("de-DE", { month: "2-digit", day: "2-digit", })
            const db = d7.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
            return '(' + dv + ' - ' + db + ')'
        },
    },
}
</script>
<style scoped>
.center-aligned >>> input {
    text-align: center
}
</style>